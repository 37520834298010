<template>
  <b-card v-if="videoData">
    <b-row>
      <!-- User Info: Left col -->
      <b-col
          cols="12"
          xl="4"
          class="d-flex justify-content-between"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start align-items-center">
          <b-avatar
              :src="videoData ? videoData.thumbnail : 'https://via.placeholder.com'"
              size="114px"
              rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ videoData.name_ar }} ({{ videoData.name }})
              </h4>

              <span class="card-text">{{ videoData.description }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                  :to="{ name: 'apps-videos-edit', params: { id: videoData.id } }"
                  variant="danger"
              >
                Edit
              </b-button>
              <b-button
                  variant="outline-danger"
                  class="ml-1"
                  @click="deleteVideo(videoData.id)"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col>
        <div class="d-flex justify-content-end align-items-center h-100">
          <!--          <video-player-->
          <!--              :options="videoOptions">-->

          <!--          </video-player>-->

          <vue-vimeo-player ref="player" :video-id="videoID"></vue-vimeo-player>

          <!--          <video-->
          <!--            width="320"-->
          <!--            height="240"-->
          <!--            controls-->
          <!--            :poster="videoData.thumbnail"-->
          <!--          >-->
          <!--            <source-->
          <!--              :src="videoData.link"-->
          <!--              type="video/mp4"-->
          <!--            >-->
          <!--            <source-->
          <!--              src=""-->
          <!--              type="video/ogg"-->
          <!--            >-->
          <!--            Your browser does not support the video tag.-->
          <!--          </video>-->
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import router from '@/router'
import VideoPlayer from "@/views/components/video/VideoPlayer";
import {vueVimeoPlayer} from 'vue-vimeo-player'

// repository
import VideoRepository from '@/abstraction/repository/videoRepository'

const videoRepository = new VideoRepository()

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    VideoPlayer,
    vueVimeoPlayer,
  },
  props: {
    videoData: {
      // type: Object,
      required: true,
    },
  },
  data() {
    return {
      pageData: this.videoData,
      videoOptions: {
        autoplay: true,
        controls: true,
        height: 250,
        width: 350,
        sources: [
          {
            src:
            this.videoData.link,
            type: "video/mp4"
          }
        ],
        poster: this.videoData.thumbnail,
      },
    };
  },
  computed: {
    videoID: {
      get: function () {
        let linkSplit = this.videoData.link.split("/")
        return linkSplit[linkSplit.length - 1]
      },
    }
  },
  methods: {
    async deleteVideo(videoId) {
      await videoRepository.delete(videoId)
      router.push({name: 'apps-videos-list'})
    },
  },

}
</script>

<style></style>
