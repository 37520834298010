<template>
  <div v-if="form">
    <template>
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <video-view-video-info-card :video-data="form" />
        </b-col>
      </b-row>
      <b-tabs
        v-if="form"
        pills
      >
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <span>
              <i class="WMi-music" />
            </span>
            <span class="d-none d-sm-inline">Music</span>
          </template>
          <b-row>
            <b-col cols="12">
              <video-view-tab-data :video-data="form" />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <span>
              <i class="WMi-user" />
            </span>
            <span class="d-none d-sm-inline text-capitalize">lyric</span>
          </template>
          <b-row>
            <b-col cols="12">
              <b-card>
                <b-card-body class="text-capitalize">
                  <b-card-title>lyric</b-card-title>
                  <b-card-sub-title>{{ form.lyric }}</b-card-sub-title>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BTabs,
    BTab,
    BCardSubTitle,
    BCardTitle,
    BCardBody,
} from 'bootstrap-vue'
import router from '@/router'
import VideoRepository from '@/abstraction/repository/videoRepository'
import VideoViewVideoInfoCard from './VideoViewVideoInfoCard.vue'
import VideoViewTabData from './VideoViewTabData.vue'

// repository

const videoRepository = new VideoRepository()

export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BCard,
        BTabs,
        BTab,
        BCardSubTitle,
        BCardTitle,
        BCardBody,
        // Local Components
        VideoViewVideoInfoCard,
        VideoViewTabData,
    },
    setup() {
        const form = ref(null)
        const videoId = ref(router.currentRoute.params.id)
        onMounted(async () => {
            if (videoId.value) {
                const resource = await videoRepository.show(videoId.value)
                form.value = resource
            }
        })
        return {
            form,
        }
    },
}
</script>

<style></style>
