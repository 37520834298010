<template>
  <b-card v-if="videoData">
    <b-row>
      <!-- Column One -->
      <b-col
        cols="12"
        sm="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="mr-50">
                <i class="WMi-headphones" />
              </span>
              <span class="font-weight-bold text-capitalize">Name</span>
            </th>
            <td class="pb-50">
              {{ videoData.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="mr-50">
                <i class="WMi-headphones" />
              </span>
              <span class="font-weight-bold text-capitalize">Arabic Name</span>
            </th>
            <td class="pb-50">
              {{ videoData.name_ar }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="mr-50">
                <i class="WMi-headphones" />
              </span>
              <span class="font-weight-bold text-capitalize">Description</span>
            </th>
            <td class="pb-50">
              {{ videoData.description }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="mr-50">
                <i class="WMi-headphones" />
              </span>
              <span class="font-weight-bold text-capitalize">Artist Name</span>
            </th>
            <td class="pb-50">
              <b-link
                class="alert-link text-danger"
                :to="{ name: 'apps-artists-view', params: { id: videoData.artist.id } }"
              >
                {{ videoData.artist.name }}
              </b-link>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="mr-50">
                <i class="WMi-headphones" />
              </span>
              <span class="font-weight-bold text-capitalize">Played Count</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ videoData.played_count ? videoData.played_count : 0 }}
            </td>
          </tr>
        </table>
      </b-col>
      <!-- Column two -->
      <b-col
        cols="12"
        sm="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="mr-50">
                <i class="WMi-headphones" />
              </span>
              <span class="font-weight-bold text-capitalize">genre</span>
            </th>
            <td class="pb-50">
              {{ videoData.genre.name_ar }} ({{ videoData.genre.name }})
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
    BLink, BCard, BCol, BRow,
} from 'bootstrap-vue'

export default {
    components: {
        BLink,
        BCard,
        BCol,
        BRow,
    },
    props: {
        videoData: {},
    },
    setup() {},
}
</script>
